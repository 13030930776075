import './App.css';
import Nav from './Navbar';
import mnogoPrugin from "./mnogoPrugin.jpg"
import giant from './giant.jpg';
import short from './short.jpg';
import spiral from './spiral.jpg';
import mecha from './mecha.jpg';
import long from './long.jpg';
import Process from './timeline';
import process from './d431ws1s.png'
import process2 from './hmlzi2c3.png'
import about1 from './about1.jpg'
import about2 from './about2.jpg'
import control from './control.jpg'
import iron from './iron.jpg'
import spring from './spring.jpg'
import { TextInput, Button } from "flowbite-react";
import { HiMail } from "react-icons/hi";
import { useEffect } from 'react';
import whatsapp from './whatsapp.png'
import cert1 from './cert1.jpg'
import cert2 from './cert2.jpg'

function Form() {
  useEffect(() => {
    const name = document.querySelector("#email4")
    const email = document.querySelector("#email2")
    const send = document.querySelector(".send")
    name.addEventListener('input', (i) => {
      send.href = "https://wa.me/79773524192?text=Имя: " + name.value
    })
    email.addEventListener('input', (i) => {
      {
        send.href = "https://wa.me/79773524192?text=Имя: " + name.value + "; E-mail: " + email.value
      }
    })
    send.href = "https://wa.me/79773524192?text=Имя: " + name.value + "; E-mail: " + email.value
  })
  return (
    <div className="max-w-md relative top-[50%] text-center left-[50%] translate-x-[-50%] translate-y-[-50%] background-transparent  backdrop-blur-sm p-5">
      <h1 className='text-white font-bold text-center mb-4 text-2xl'>Напишите нам</h1>
      <TextInput className='mb-4 name' id="email4" type="text" icon={HiMail} placeholder="Ваше имя" required />
      <TextInput className='mb-4 name' id="email2" type="email" icon={HiMail} placeholder="Ваш E-mail" required />
      <a className='mx-auto send bg-cyan-700 px-5 py-3 text-white rounded-lg' href='#'>Отправить заявку</a>
    </div>
  )
}


function App() {
  useEffect(() => {
    const inverted = document.querySelector(".inverted")
    const links = document.querySelector('.link')
    const links2 = document.querySelector('.link2')
    const links3 = document.querySelector('.link3')
    const links4 = document.querySelector('.link4')
    const links5 = document.querySelector('.link5')
    const links6 = document.querySelector('.link6')
    const links7 = document.querySelector('.link7')
    window.onscroll = () => {
      if (window.scrollY >= 830) {

        inverted.classList.remove("text-white")
        inverted.classList.remove("max-md:text-white")
        links.classList.remove("text-white")
        links.classList.remove("max-md:text-white")
        links2.classList.remove('text-white')
        links2.classList.remove('max-md:text-white')
        links3.classList.remove('text-white')
        links3.classList.remove('max-md:text-white')
        links4.classList.remove('text-white')
        links4.classList.remove('max-md:text-white')
        links5.classList.remove('text-white')
        links5.classList.remove('max-md:text-white')
        links6.classList.remove('text-white')
        links6.classList.remove('max-md:text-white')
      } else {
        inverted.classList.add("text-white")
        inverted.classList.add("max-md:text-white")
        links.classList.add("text-white")
        links.classList.add("max-md:text-white")
        links2.classList.add('text-white')
        links2.classList.add('max-md:text-white')
        links3.classList.add('text-white')
        links3.classList.add('max-md:text-white')
        links4.classList.add('text-white')
        links4.classList.add('max-md:text-white')
        links5.classList.add('text-white')
        links5.classList.add('max-md:text-white')
        links6.classList.add('text-white')
        links6.classList.add('max-md:text-white')
      }
    }
  })
  return (
    <div className="App bg-slate-100">
      <Nav></Nav>

      <main>
        <Form></Form>
      </main>

      <div className='we-are-creating bg-slate-100' id='weAreCreating'>
        <h1 className='text-blue-500 text-center uppercase text-[32pt] montalt mt-10 font-bold tracking-wide'>Что мы производим</h1>
        <div className='cards mt-10 text-center place-content-center'>

          <div className="card-2 lg:w-[500px]">
            <img src={giant} className='w-full h-[300px] overflow-hidden' alt="pks" />
            <p className='mt-4 text-black text-lg'><h1 className='mb-2 text-blue-600 uppercase font-semibold text-xl'>пружины сжатия</h1>
              Пружины сжатия применяются во всех типах техники например, в амортизаторах машин, подвесках трубопроводов. Они есть даже в спальных матрацах и авторучках. Такие пружины работают путем восприятия продольно-осевой нагрузки, при которой происходит их сжатие. Пружины сжатия от нашего Завода проходят обязательную термообработку и строгий измерительный контроль.</p>
          </div>

          <div className="card-2 lg:w-[500px]">
            <img src={long} className='w-full h-[300px] overflow-hidden' alt="pks" />
            <p className='mt-4 text-black text-lg'><h1 className='mb-2 text-blue-600 uppercase font-semibold text-xl'>пружины растяжения</h1>
              В свободном состоянии пружина растяжения сделана таким образом, что витки обычно соприкасаются друг с другом. Витки снабжены различными зацепами и имеют цилиндрическую винтовую структуру. <br /> Пружины растяжения от Московского пружинного завода проходят термообработку и строгую проверку измерений.</p>
          </div>

          <div className="card-2 lg:w-[500px]">
            <img src={short} className='w-full h-[300px] overflow-hidden' alt="pks" />
            <p className='mt-4 text-black text-lg'><h1 className='mb-2 text-blue-600 uppercase font-semibold text-xl'>пружины кручения</h1> Пружины кручения необходимы для работы механизмов, образующих кручение. Используются в тех устройствах, где нужна работа пружины на изгиб. <br /> Пружины кручения от нашего завода это качественные и надежные изделия, обеспечивающие долговечную работу устройства.</p>
          </div>

          <div className="card-2 lg:w-[500px]">
            <img src={mecha} className='w-full h-[300px] overflow-hidden' alt="pks" />
            <p className='mt-4 text-black text-lg'><h1 className='mb-2 text-blue-600 uppercase font-semibold text-xl'>тарельчатые пружины</h1>
              Тарельчатая пружина применяется там, где необходимы большие усилия при микродеформациях. Московский пружинный завод предлагает услуги по производству и реализации тарельчатых пружин.</p>
          </div>


          <div className="card-1 lg:w-[500px]">
            <img src={spiral} className='h-[300px] overflow-hidden w-full' alt="pks" />
            <p className='mt-4 text-black text-lg'>
              <h1 className='mb-2 text-blue-600 uppercase font-semibold text-xl'>пружины из ленты</h1>
              Пружины из ленты применяются как кинематические элементы и элементы реле. Они также выполняют роль измерителей и двигателей механизмов приборов. Московсий пружинный завод предлагает услуги по изготовлению и продаже пружин из лент.</p>
          </div>
          <div className="card-2 lg:w-[500px]">
            <img src={mnogoPrugin} className='w-full h-[300px] overflow-hidden' alt="pks" />
            <p className='mt-4 text-black text-lg'>
              <h1 className='mb-2 text-blue-600 uppercase font-semibold text-xl'>конические пружины</h1>
              Наша компания производит конические пружины самых разных типов, что позволяет потенциальному заказчику достаточно быстро определиться с выбором необходимого ему изделия для использования его в той или иной конструкции.</p>
          </div>




        </div>
      </div>



      <div className='we-are-creating bg-slate-300 px-20 mt-10 pb-10 max-md:px-3' id='services'>
        <h1 className='text-blue-500 text-center uppercase text-[32pt] montalt mt-5 font-bold tracking-wide mb-10'>Наши услуги:</h1>
        <div className="flex justify-around text-center max-md:grid">
          <div className="item-1 w-[300px]">
            <span className="material-symbols-rounded text-8xl overflow-hidden text-blue-700">room_preferences</span>
            <h2 className="text-xl font-bold uppercase monts tracking-wider mt-3">Разработка</h2>
            <p className='mt-2'>Наша команда разработает под ваши нужды любые виды пружин. Свяжитесь с нами для составления проекта изделия.</p>
          </div>
          <div className="item-2 w-[300px]">
            <span className="material-symbols-rounded text-8xl overflow-hidden text-blue-700">thumb_up</span>
            <h2 className="text-xl font-bold uppercase monts tracking-wider mt-3">Контроль качества</h2>
            <p className='mt-2'>
              Доставляем готовые пружины удобным для вас способом. Находим самые выгодные формы доставки в ваш город.
            </p>
          </div>
          <div className="item-3 w-[300px]">
            <span className="material-symbols-rounded text-8xl overflow-hidden text-blue-700">flowsheet</span>
            <h2 className="text-xl font-bold uppercase monts tracking-wider mt-3">производство</h2>
            <p className='mt-2'>
              Наши сотрудники в кратчайшие сроки изготовят пружины. Весь процесс производства строго соблюдается по нормам ГОСТ и ТЗ.
            </p>
          </div>
        </div>
      </div>



      



      <div className='we-are-creating bg-slate-100 px-20  max-md:px-3' id='about'>
        <hr className='relative top-10 shadow' />
        <h1 className='text-blue-500 text-center uppercase text-[32pt] montalt mt-10 font-bold tracking-wide mb-10'>О нас</h1>
        <div className='grid gap-y-4'>
          <p>
            <strong>ООО «Московский Пружинный Завод»</strong> - это коллектив высококвалифицированных специалистов, это команда работающая над общим делом, для которых предприятие это важная часть их жизни. Работа в дружной команде позволяет нам успешно работать на рынке, быть конкурентоспособнами и достигать поставленных целей.
          </p>
          <p>
            Мы занимаемся производством и проектированием качественных пружин любой формы для различных отраслей современной Российской промышленности, в том числе и предприятий военно-промышленного комплекса. При изготовлении продукции мы используем только качественное сырьё отечественного производства или аналоги от европейских производителей.
          </p>
         
          <div className="flex h-96 justify-around items-center overflow-hidden">
            <img src={about1} alt="about" />
            <img src={about2} alt="about" />
          </div>
         
        </div>


      </div>


      <div className='we-are-creating bg-slate-100 px-20  max-md:px-3' id='whyUs'>
        <hr className='relative top-10 shadow' />
        <h1 className='text-blue-500 text-center uppercase text-[32pt] montalt mt-10 font-bold tracking-wide mb-5'>Почему стоит работать с нами?</h1>
        <h1 className="text-2xl font-bold tracking-wide">Потому что мы лучшие в своём деле!</h1>
        <div className="grid mt-5">
          <div className="col-1 grid gap-y-5">
            <div className="item1 flex max-md:grid max-md:text-center">
              <img src={iron} className='h-52 w-96 max-md:mx-auto' alt="why-me" />
              <div className="body ml-[60px] max-md:ml-0 mt-2">
                <h1 className='text-xl font-bold mb-1'>Качественный материал</h1>
                <p className='w-[600px] max-md:w-full'>Материал СТ, 70, 65г, 60с2а, 51ХФА, 65С2ВА,40х 13, 12Х18Н10Т от 0.1 до 30мм. Рассматриваем возможность изготовления пружин из других марок стали, а так же из материалов заказчика.</p>
              </div>
            </div>
            <div className="item2 flex max-md:grid max-md:text-center">
              <img src={control} className='h-52 w-96 max-md:mx-auto' alt="why-me" />
              <div className="body ml-[60px] max-md:ml-0 mt-2">
                <h1 className='text-xl font-bold mb-1'>Контроль качества</h1>
                <p className='w-[600px] max-md:w-full'>Продукция проходит  контроль качества по геометрическим и силовым показателям на сертифицированном измерительном оборудовании. При запросе выдаются паспорта качества на продукцию.</p>
              </div>
            </div>
            <div className="item3 flex max-md:grid max-md:text-center">
              <img src={spring} className='h-52 w-96 max-md:mx-auto' alt="why-me" />
              <div className="body ml-[60px] max-md:ml-0 mt-2">
                <h1 className='text-xl font-bold mb-1'>Обработка</h1>
                <p className='w-[600px] max-md:w-full'>Термообработка, отпуск, заневоливание. Обработка торцов методом холодной шлифовки, пескоструйная и дробеструйная обработка. <br />Покрытие: Цхр., Хим.окс., прм., Кд</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='contacts' className='we-are-creating bg-slate-300 px-20 mt-10 pb-10'>
        <h1 className='text-blue-500 text-center uppercase text-xl montalt mt-5 font-bold tracking-wide mb-10'>Контакты</h1>
        <div className='flex justify-around items-center max-md:block max-md:text-center'>
          <div className="item max-md:py-2">
            <p>Адрес: МО, Серпухов, ул. Пушкина, д45</p>
            <p>Тел.: +7(977)352-41-92</p>
            <p>Эл.почта: mpzmsk@mail.ru</p>
          </div>
          <div className="item max-md:py-2">
            <p>Бухгалтерия:</p>
            <p>Тел.: +7(901)901-25-16</p>
            <p>Эл.почта: buh2516@mail.ru</p>
          </div>
          <div className="item max-md:py-2">
            <p>Отдел продаж:</p>
            <p>1) Тел.: +7(977)123-47-38</p>
          </div>
          <div className="item max-md:py-2">
            <p>Отдел производства и логистики:</p>
            <p>Тел.: +7(901)901-25-19</p>
          </div>
          <div className="item max-md:py-2 flex justify-around">
            <a href="https://firebasestorage.googleapis.com/v0/b/fluted-equinox-308115.appspot.com/o/cert1.jpg?alt=media&token=0cbf2b6f-17f7-4805-b322-6002046eab9d"><img src={cert1} width={100} alt="" /></a>
            <a href="https://firebasestorage.googleapis.com/v0/b/fluted-equinox-308115.appspot.com/o/cert2.jpg?alt=media&token=b2e96d06-af9d-4d7e-b938-f637bcbe451b"><img src={cert2} width={100} alt="" /></a>
          </div>
          <div className="item max-md:py-2 flex justify-center">
            <a href="https://wa.me/79773524192"><img className='h-10' src={whatsapp} alt="" /></a>
          </div>
        </div>
        <p className='flex justify-end max-md:justify-center'>
          <span className='material-symbols-rounded mr-2'>copyright</span> <span>Все права защищены</span>
        </p>
      </div>

    </div>
  );
}

export default App;
