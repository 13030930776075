import { Button, Timeline } from "flowbite-react";
import { HiArrowNarrowRight, HiCalendar } from "react-icons/hi";

function Process() {
  return (
    <Timeline>
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Title>Производство осуществляться как на пружинных автоматах, так и на токарных станках (для единичного выпуска).</Timeline.Title>
        <Timeline.Body>
        Пружинная проволом подится через направляющую оправку на вал, где фиксируется специальным захватом на токарном патронаПружинная проволом подится через направляющую оправку на вал, где фиксируется специальным захватом на токарном патрона
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Title>Специалист включает станок и при вращении вала проволока начинает на него наматываться.</Timeline.Title>
        <Timeline.Body>
        Сделав несколько оборотов вокруг пси (мамотав несколько поджатых витков) специалист включает подачу, тем самым задаа шаг УЭЛ. Подсчет количества витков, может осуществляться как визуально, так с помощью счетчика. Отсчитав необходимое количество рабочих витков, специалист отключает подачу и наматывает еще несколько поджатых витков будущего изделия. После чего выключает станок, отрезает пружинную спираль
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Title>Полученная заготовка, после контроля ОТК, переходит в шлифовальный цех,</Timeline.Title>
        <Timeline.Body>
        в котором отрежут лишние поджатые витки и обработают торцы для придания ой устойчивости.
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Title>После чего заторцованные заготовки следуют на термообработку.</Timeline.Title>
        <Timeline.Body>
        Режим термообработки пружин выбирается в зависимости от их материала. Для сталей 60С2А, 51ХФА - закалка и отпуск. Для нержавеющих сталей, бронз и нагартованной углеродистой стали 9389 низкотемпературный отпуск. При термообработке разные пружинные материалы могут деформироваться или остаться без изменений. Винтовые сжатия и растяжения из проволоки 9389-75 скручиваются, уменьшаясь в диаметре, из нержавейки наоборот раскручиваются. Пружинные изделия из закаливаемых марок сталей непредсказуемо, в зависимости от состояния поставки и тех механических напряжений которые заложил производитель в материал.
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Title>Упругие элементы после термообработке следуют на технологическое обжатие</Timeline.Title>
        <Timeline.Body>
        (5-ти, 10 ти кратное сжатие до соприкосновения витков) и контроль ОТК на соответствие геометрическия и силовых характеристик требованиям ГОСТ и КД. После чего, направляются на антикоррозионное покрытие гальваническое (цинк, кивлическое оксидирование или фосфатирование, никелирование) или лакокрасочное
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
  </Timeline>
  );
}
export default Process;