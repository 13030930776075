
"use client";

import { Navbar } from "flowbite-react";
import logo from "./logo.jpg"
function Nav() {
  return (
    <Navbar fluid rounded className="fixed w-full bg-transparent backdrop-blur-lg z-[999]">
      <Navbar.Brand href="https://mpzmsk.ru">
    <img src={logo} className="mr-3 h-6 sm:h-9 rounded-full" alt="Flowbite React Logo" />
        <span className="self-center whitespace-nowrap text-xl font-semibold inverted text-white max-md:hidden">Московский Пружинный Завод</span>
        <span className="self-center whitespace-nowrap text-xl font-semibold inverted hidden max-md:block">МПЗ</span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="pr-20 py-2">
        <Navbar.Link href="#" className="text-xl link text-white">
          В начало
        </Navbar.Link>
        <Navbar.Link href="#weAreCreating" className="text-xl link2 text-white">
          Что производим
        </Navbar.Link>
        <Navbar.Link href="#services" className="text-xl link3 text-white">Услуги</Navbar.Link>
        <Navbar.Link href="#process" className="text-xl link4 text-white">Процесс</Navbar.Link>
        <Navbar.Link href="#about" className="text-xl link6 text-white">О нас</Navbar.Link>
        <Navbar.Link href="#whyUs" className="text-xl link5 text-white">Почему мы</Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default Nav;